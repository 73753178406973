import Loadable from '@loadable/component';
import React from 'react';

import About from '../components/About';
import Brands from '../components/Brands';
import Hero from '../components/Hero';
import SEO from '../components/SEO';
import JSONLD from '../components/jsonld';
import Layout from '../layout';

const LoadableDemo = Loadable(() => import('../components/Demo'));
const LoadableFeatureList = Loadable(() => import('../components/FeatureList'));
const LoadableCTA = Loadable(() => import('../components/CTA'));
const LoadableFAQ = Loadable(() => import('../components/homepage/FAQ'));
const LoadableKilian = Loadable(() => import('../components/Kilian2'));

class Index extends React.Component {
  render() {
    return (
      <Layout>
        <SEO title="" keywords={['']} />
        <Hero />
        <Brands />
        <About />
        <LoadableDemo />
        <LoadableFeatureList />
        <LoadableCTA />
        <LoadableFAQ />
        <LoadableKilian />
        <JSONLD />
      </Layout>
    );
  }
}

export default Index;

import { css } from 'astroturf';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

const styles = css`
  @keyframes updown {
    from {
      transform: translateY(0px) translateZ(0);
      filter: drop-shadow(0px -1px 1px rgba(255, 255, 255, 0.25)) drop-shadow(20px 10px 20px rgba(34, 33, 81, 0.25));
    }
    to {
      transform: translateY(-20px) translateZ(60px);
      filter: drop-shadow(0px -1px 1px rgba(255, 255, 255, 0.25)) drop-shadow(40px 40px 20px rgba(34, 33, 81, 0.15));
    }
  }

  @keyframes glare {
    from {
      transform: translateX(0);
      opacity: 1;
    }
    to {
      transform: translateX(40px);
      opacity: 0.25;
    }
  }

  .appWrapper {
    z-index: 3;
    margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 800px;
    transform-style: preserve-3d;
    backface-visibility: hidden;
    transform: perspective(5000px);

    @media (min-width: 1024px) {
      transform: perspective(1000px) rotateX(6deg) rotateY(-25deg) rotateZ(4deg);
      margin: 0 0 0 -50px;
      transition: 0.5s ease-in-out transform;
    }
  }

  .app {
    width: 100%;
    border-radius: 5px;
    overflow: hidden;
    box-shadow:
      rgba(50, 50, 93, 0.3) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.5) 0px 30px 60px -30px,
      rgba(103, 178, 111, 0.3) -10px 10px 60px -10px;

    @media (min-width: 1024px) {
      width: 800px;
    }
  }

  .devices {
    width: 85%;
    position: absolute;
    top: 15%;
    left: 0;
    transform: translateZ(50px);
    transform-style: preserve-3d;
  }

  .mobile {
    width: 20%;
    border-radius: 5px;
    display: block !important;
    position: absolute !important;
    left: 0px;
    transform: translateY(0px) translateZ(0);
    filter: drop-shadow(0px -1px 1px rgba(255, 255, 255, 0.25)) drop-shadow(20px 10px 20px rgba(34, 33, 81, 0.25));
    backface-visibility: hidden;

    @media (prefers-reduced-motion: no-preference) {
      animation-name: updown;
      animation-duration: 7s;
      animation-delay: 3.5s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-fill-mode: forwards;
      filter: drop-shadow(0px -1px 1px rgba(255, 255, 255, 0.25)) drop-shadow(20px 10px 20px rgba(34, 33, 81, 0.25));
    }
  }

  .tablet {
    width: 30%;
    border-radius: 5px;
    display: block !important;
    position: absolute !important;
    left: 25%;
    transform: translateY(0px) translateZ(0);
    box-shadow:
      0px -1px 1px 0 rgba(255, 255, 255, 0.25),
      20px 10px 20px 0 rgba(34, 33, 81, 0.25);
    backface-visibility: hidden;

    @media (prefers-reduced-motion: no-preference) {
      & {
        animation-name: updown;
        animation-duration: 7s;
        animation-delay: 6.5s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: infinite;
        animation-direction: alternate;
        animation-fill-mode: forwards;
        filter: drop-shadow(0px -1px 1px rgba(255, 255, 255, 0.25)) drop-shadow(20px 10px 20px rgba(34, 33, 81, 0.25));
      }
    }
  }

  .laptop {
    width: 40%;
    border-radius: 5px;
    display: block !important;
    position: absolute !important;
    left: 60%;
    transform: translateY(0px) translateZ(0);
    box-shadow:
      0px -1px 1px 0 rgba(255, 255, 255, 0.25),
      20px 10px 20px 0 rgba(34, 33, 81, 0.25);
    backface-visibility: hidden;

    @media (prefers-reduced-motion: no-preference) {
      animation-name: updown;
      animation-duration: 7s;
      animation-delay: 9.5s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-direction: alternate;
      animation-fill-mode: forwards;
      filter: drop-shadow(0px -1px 1px rgba(255, 255, 255, 0.25)) drop-shadow(20px 10px 20px rgba(34, 33, 81, 0.25));
    }
  }
`;

export default () => (
  <div className={styles.appWrapper}>
    <StaticImage
      src="../../assets/images/hero/app.png"
      className={styles.app}
      alt=""
      loading="eager"
      placeholder="none"
      breakpoints={[200, 300, 400, 750, 1080, 1366, 1920]}
      backgroundColor="#353447"
    />

    <div className={styles.devices}>
      <StaticImage
        src="../../assets/images/hero/mobile.png"
        className={styles.mobile}
        alt=""
        loading="eager"
        placeholder="none"
        breakpoints={[200, 300, 400, 750, 1080, 1366, 1920]}
        backgroundColor="#353447"
      />
      <StaticImage
        src="../../assets/images/hero/tablet.png"
        className={styles.tablet}
        alt=""
        loading="eager"
        placeholder="none"
        breakpoints={[200, 300, 400, 750, 1080, 1366, 1920]}
        backgroundColor="#f6fafd"
      />
      <StaticImage
        src="../../assets/images/hero/laptop.png"
        className={styles.laptop}
        alt=""
        loading="eager"
        placeholder="none"
        breakpoints={[200, 300, 400, 750, 1080, 1366, 1920]}
        backgroundColor="#353447"
      />
    </div>
  </div>
);
